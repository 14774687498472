import { default as _errordWuaACUMlnMeta } from "/opt/node_app/app/src/pages/_error.vue?macro=true";
import { default as TemplatePageHo6LoeY0HBMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue?macro=true";
import { default as KitchenSinkLsWVWY2PSAMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue?macro=true";
import { default as BackofficeComponentPreviewZMsqupnWg8Meta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue?macro=true";
import { default as ReadyProbe8bLsUhXtDwMeta } from "/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue?macro=true";
export default [
  {
    name: "_error",
    path: "/_error",
    component: () => import("/opt/node_app/app/src/pages/_error.vue")
  },
  {
    name: "template-page",
    path: "/:slug(.*)*",
    meta: {"middleware":["headless-middleware"]},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/TemplatePage.vue")
  },
  {
    name: "kitchen-sink",
    path: "/_kitchen-sink",
    meta: {"layout":false,"pageTransition":false,"layoutTransition":false},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/KitchenSink.vue")
  },
  {
    name: "umb-backoffice-component-preview",
    path: "/umb-backoffice-component-preview",
    meta: {"layout":false,"pageTransition":false,"layoutTransition":false},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/BackofficeComponentPreview.vue")
  },
  {
    name: "nuxt-ready-probe",
    path: "/_probes/nuxt/ready",
    meta: {"layout":false,"pageTransition":false,"layoutTransition":false},
    component: () => import("/opt/node_app/app/node_modules/.pnpm/@edco+apollo@0.1.754_ioredis@5.4.1_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_t_l3xs7gdlrvnuq4pcznioffhtqm/node_modules/@edco/apollo/dist/runtime/pages/ReadyProbe.vue")
  }
]